<template>
  <div v-if="banners && banners.length" class="banners">
    <a
      v-for="(item, index) in banners"
      :key="index"
      :title="item.title"
      class="banner"
      :href="item.link"
      target="_blank"
    >
      <div class="img__container">
        <img :src="item.head_img | image($store.state.main_api)" :alt="item.head_img | image_alt" />
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'BannersComponent',
  props: {
    banners: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"

.banners {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap 32px
  margin-top: 48px;
  overflow: auto;
  +below(768px) {
    margin-top: 24px;
  }
  +below(680px) {
    gap 24px
  }
  +below(380px) {
    gap 16px
  }
}

.banner {
  ratio(350, 312)
  border 1px solid var(--color_gray)
  +below(768px) {
    width 250px
  }

  &:after {
    content: ""
    display: block
    width: 100%
    height: 100%
    absolute left top right bottom
    background: rgba(0, 0, 0, 0.15)
    z-index: 10
    opacity: 0
    transition: .2s
    pointer-events none
  }

  &:hover:after {
    opacity: 1
  }
}
</style>
