<template>
  <main class="main">
    <div class="main__inner">
      <section class="section">
        <div class="minister__container">
          <div class="minister__img">
            <img :src="minister.head_img | image($store.state.api)" :alt="minister.head_img | image_alt" />
          </div>
          <div class="minister__info">
            <div class="minister__name">
              {{ minister.surname }} <br />
              {{ minister.name }} <br />
              {{ minister.patronymic }}
            </div>
            <div v-if="minister.post && minister.post.title" class="minister__description">
              {{ minister.post.title }}
            </div>
            <div v-if="minister.department && minister.department.department_info" class="minister__contacts">
              <div v-if="minister.department.department_info.phone" class="minister__phone">
                {{ minister.department.department_info.phone}}
              </div>
              <a v-if="minister.department.department_info.email" :href="`mailto:${minister.department.department_info.email}`" class="link__blue">
                {{ minister.department.department_info.email }}
              </a>
            </div>
          </div>
        </div>
      </section>
      <section v-if="hasText(minister.description)" class="section">
        <h3>Биография</h3>
        <EditorJSComponent
          :text="JSON.parse(minister.description)"
          v-if="isJson(minister.description) && JSON.parse(minister.description)"
        />
        <span v-else>{{ minister.description }}</span>
      </section>
      <section class="section">
        <h3>Обеспечивает координацию и контроль деятельности</h3>
        <div class="minister__orgs-list">
          <a v-if="minister.department && minister.department.title" class="link__blue" href="#">
            {{ minister.department.title }}
          </a>
<!--          <a class="link__blue">-->
<!--            Управление экономического развития, инвестиций и внешнеэкономических связей;-->
<!--          </a>-->
<!--          <a class="link__blue">-->
<!--            Управление экономического развития, инвестиций и внешнеэкономических связей;-->
<!--          </a>-->
<!--          <a class="link__blue">-->
<!--            Управление экономического развития, инвестиций и внешнеэкономических связей;-->
<!--          </a>-->
        </div>
        <BannersComponent
          v-if="banners && banners.data"
          :banners="banners.data"
        />
      </section>
    </div>
  </main>
</template>

<script>
import BannersComponent from '@/components/BannersComponent.vue'
import EditorJSComponent from 'components/EditorJSComponent.vue'

export default {
  name: 'MinisterPage',
  async asyncData ({ store, route, apollo }) {
    await store.dispatch('GET_MINISTER_PAGE', {
      apollo: apollo,
      variables: {
        id: parseInt(route.params.id)
      }
    })
  },
  computed: {
    minister () {
      return this.$store.state.minister
    },
    banners () {
      return this.$store.state.banners
    }
  },
  methods: {
    hasText (text) {
      if (this.isJson(text)) {
        const parsedText = JSON.parse(text)
        return !!(parsedText && parsedText.blocks && parsedText.blocks.length)
      }
      return !!text
    },
    isJson (str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    }
  },
  metaInfo () {
    return this.$seo(
      'common',
      'Официальный сайт Главы города Махачкалы',
      '',
      '',
      'Официальный сайт Главы города Махачкалы',
      '',
      ''
    )
  },
  jsonld () {
    return {
      '@context': 'https://schema.org',
      '@type': 'Organization'
    }
  },
  components: {
    BannersComponent,
    EditorJSComponent
  }
}
</script>

<style lang="stylus">
@import "~@/styles/parts/minister.styl"
@import "~@/styles/parts/contacts.styl"

.minister__orgs-list {
  display flex
  flex-direction column
  align-items flex-start
  gap 24px
}
</style>
